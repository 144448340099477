import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import Titlebar from "common/components/Titlebar";
import FAQToggle from "common/components/FAQs";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

const FAQPage = () => {


  const seoData = {
    title: 'Radiance Hair Studio FAQs | Your Hair Care Questions Answered',
    description: 'Find answers to common questions about hair care, treatments, and services at Radiance Hair Studio. Explore our FAQs page for expert advice.',
    keywords: ['FAQ']
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "@id": "https://www.radiancehairstudio.com/faq.php",
    "additionalType": "https://www.radiancehairstudio.com/faq.php",
    "alternateName": "Radiance Hair Studio",
    "description": "Find answers to common questions about hair care, treatments, and services at Radiance Hair Studio. Explore our FAQs page for expert advice.",
    "keywords": "FAQ, hair care questions, Radiance Hair Studio FAQs",
    "image": "https://www.radiancehairstudio.com/home/home-image-18.webp",
    "mainEntity": []
  };
  
  // const faqs = [
  //   {
  //     title: "Why shall I go for this replacement?",
  //     description:
  //       "There are three conditions when you must go for these methods – having a bald head, facing the loss of hair and looking for a style statement. In all the cases, apply this hair styling method includes wig pasting. International standard glue or both sided tapes are used for the method, and in each case, there remains no side effect at all for any Hair Replacement in Delhi.",
  //   },
  //   {
  //     title: "Is there any side effect?",
  //     description:
  //       "All the processes that are applied by Radiance are external and hence there remains no chance of side-effects. Few types of glue or tapes are used, but those are non-effecting for your skull.",
  //   },
  //   {
  //     title: "Can there be any hair-loss?",
  //     description:
  //       "There is absolutely no chance of hair loss for you while applying hair wigs in Delhi. The treatment that is done here at Radiance is done on your skull and out professionals avoids treating your existing hair’s root. So, no chances of hair loss remain.",
  //   },
  //   {
  //     title: "Can I change the style?",
  //     description:
  //       "In case of Bonding or any other type of hair wigs, you can easily change the applied hairstyle. When you reach Radiance, our professionals will be guiding you in this regards. You can apply colors on your hair, use different patches.",
  //   },
  // ];

  const faqs = [
    {
      title: "Why should I opt for this hair replacement?",
      description:
        "There are three conditions when you must go for these methods – having a bald head, facing the loss of hair and looking for a style statement. In all the cases, applying this hair styling method includes wig pasting. International standard glue or both sided tapes are used for the method, and in each case, there remains no side effect at all for any Hair Replacement in Delhi. ",
    },
    {
      title: "Are there any side effects?",
      description:
        "All the procedures at Radiance are non-invasive and completely external, ensuring no risk of side effects. While adhesives such as glue or tape may be used, they are skin-friendly and do not cause any harm to the scalp.",
    },
    {
      title: "Can hair loss occur due to the treatment?",
      description:
        "No, there is no risk of hair loss when applying hair wigs in Delhi. At Radiance, our professionals ensure that the process is carried out on the scalp without interfering with the roots of your existing hair, making it a safe and effective solution.",
    },
    {
      title: "Can I change my hairstyle?",
      description:
        "Yes, with bonding or other types of hair wigs, you can easily switch up your hairstyle. Whether you want to experiment with different hair colors or apply various hair patches, our experts at Radiance will guide you through the process to achieve your desired look.",
    },
  ];

  const faqs1 = [
    {
      title:
        "Why should I choose a hair volumizer over volumizing products?",
      description:
        "Volumizing products or additional volumizer are personal choices of course. But with additional volumizer, one gets a long term better solution for hair thinning.",
    },
    {
      title:
        "What is the difference between hair extensions and a hair volumizer?",
      description:
        "Hair extensions primarily add length to your natural hair, enhancing its overall appearance. In contrast, a hair volumizer is designed to increase hair density, making the scalp look fuller with additional strands.",
    },
    {
      title: "How long does a hair volumizer last?",
      description:
        "With proper care and maintenance, a high-quality human hair volumizer can last up to a year, ensuring long-term volume and a natural look.",
    },
    {
      title:
        "Does a volumizer harm natural hair?",
      description:
        "No, a hair volumizer is carefully hand-tied to the roots without the use of chemicals or harsh products. This ensures a secure fit without causing any damage to your scalp or existing hair.",
    },
    {
      title: "Can I style my hair after using a volumizer?",
      description:
        "Yes, you can style your hair just as you would normally—whether it’s coloring, curling, straightening, or other styling techniques. However, to maintain the quality of the volumizer, it’s best to avoid excessive heat and chemical treatments.",
    },
  ];

  const faqs2 = [
    {
      title: "How simple is it to wear a wig?",
      description:
        "We at Radiance will be offering you a man's hair wig in Delhi that you may wear as a cap with ease. You can choose to open it or wear it.",
    },
    {
      title: "Will I have to pay extra for longer hair?",
      description:
        "Most standard wigs come with a length of 6 to 8 inches, which is generally sufficient for men. Since this length is ideal, there is no additional charge for extra-long hair.",
    },
    {
      title: "Can one wig provide full coverage?",
      description:
        "At Radiance, we offer four types of wigs for men—front coverage, back coverage, baby hair, and full perimeter hair. You can select the most suitable option based on your hair loss pattern for seamless coverage.",
    },
    {
      title: "How natural will the wig look?",
      description:
        "Our wigs are designed to blend effortlessly with your natural hair, ensuring a realistic and undetectable appearance. With high-quality materials and expert craftsmanship, they mimic real hair, giving you a completely natural look.",
    },
  ];

  const faqs3 = [
    {
      title: "Will the process cause hair loss?",
      description:
        "It is important to note right away that this technique is applied externally. Here, your skull is covered with adhesive that won't come into contact with your natural hair. Therefore, there is no longer any possibility of hair loss.",
    },
    {
      title: "How often can I change my hairstyle?",
      description:
        "You can switch up your look as often as you like—even daily! With a variety of hair patches and color options available, you have the flexibility to experiment without worrying about any impact on your hairpieces. These extensions are designed to handle frequent styling changes effortlessly.",
    },
    {
      title: "Are there any side effects on the scalp?",
      description:
        "The adhesive used for hair bonding at Radiance meets international safety standards, ensuring a secure and irritation-free experience. Our skilled professionals take every precaution to minimize any chance of discomfort or side effects.",
    },
    {
      title:
        "Can someone with natural hair still have this treatment?",
      description:
        "Absolutely! This technique is applied directly to the scalp, meaning your natural hair remains unaffected. Only minimal trimming is required to ensure a perfect blend with your desired style.",
    },
  ];

  const faqs4 = [
    {
      title: "Will my natural hair be affected?",
      description:
        "No, this method does not involve any adhesives, glues, or tapes. Instead, your natural hair is carefully woven to create a foundation for the extensions. Since it is a Non-Surgical Hair Replacement technique, there is absolutely no risk of hair damage.",
    },
    {
      title: "Are there any side effects?",
      description:
        "This process is entirely external and does not involve the use of chemicals. As a result, there is minimal to no chance of side effects, ensuring both your scalp and natural hair remain unharmed.",
    },
    {
      title: "Can I change my hairstyle daily?",
      description:
        "Yes, you can! The hair extensions used in weaving are secured with clips or double-sided tapes, making them easy to remove and reapply. This allows you to switch up your style effortlessly without any inconvenience.",
    },
    {
      title: "Do I need to avoid swimming or other activities?",
      description:
        "Not at all! Since the extensions blend naturally with your hair and do not involve harsh chemicals or adhesives, you can enjoy activities like swimming without worrying about damage.",
    },
  ];

  const faqs5 = [
    {
      title: "Does the procedure cause any discomfort?",
      description:
        "Hair Clipping The literal answer to this question is – by No means. The extension application method of clip-in that we apply at Radiance is fully an external one and there is surgery into it. So, there is not even minute chance of pain in the entire process.",
    },
    {
      title: "Do I have to wear extensions all the time?",
      description:
        "Not at all! Clip-in extensions are designed for easy removal and reuse. The best part? You don’t need to visit Radiance every time you want to wear them again. Simply take them off whenever you like and reattach them whenever needed.",
    },
    {
      title: "How long do clip-in extensions last?",
      description:
        "The lifespan of clip-in extensions depends on how well they are maintained. As long as the clips stay secure and you handle them with care, they can last a long time. Our experts at Radiance will provide guidance to help you get the most out of your extensions.",
    },
    {
      title: "Can I wash my extensions with shampoo?",
      description:
        "Yes, you can! Since our extensions are made from 100% natural human hair, they can be washed just like your own hair using a mild shampoo. Regular care ensures they remain soft, shiny, and long-lasting.",
    },
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <Titlebar title="Frequently Asked Questions" />
          <div className="container pt-5">
            <div className="row">
              <div className="col-md-12">
                <div class="card mb-4 ">
                  <div class="card-body p-4">
                    <div class="faq-description">
                      <p className="fqa_subjead">Hair Extension</p>
                      <h4>FAQs of <a href="/hair-replacement-for-men.php" style={{ color: "#000"}}>Hair Replacement</a> for Men's</h4>
                      <p>
                      Hair Extension We know that it is not very easy to go for a complete transformation process. Many questions might have come to your mind while thinking of going for a hair replacement. That’s why here we tried to answer some of them. 
                      </p>
                      <FAQToggle faqs={faqs} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{marginBottom: '25px'}}>
              <div className="col-md-6">
                <div class="card">
                  <div class="card-body p-4">
                    
                    <div class="faq-description">
                      <p className="fqa_subjead">Hair Bonding</p>
                      <h4><a href="/hair-bonding-for-men.php" style={{ color: "#000"}}>Hair Bonding</a> FAQs</h4>
                      <p>
                      Bonding of Hair Here, we attempted to address a few pertinent, targeted queries that clients commonly have regarding hair bonding.. You may find them relevant too. 
                      </p>

                      <FAQToggle faqs={faqs3} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 equall">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="faq-description">
                      <p className="fqa_subjead">Customized Wigs</p>
                      <h4>Customized Wigs FAQs</h4>
                      <p>
                      Personalised Wigs These are some queries you may have had when considering getting a hair wig. By providing answers below, we hope to clear up any confusion you may have had.
                      </p>

                      <FAQToggle faqs={faqs2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div class="card mb-4 ">
                  <div class="card-body p-4">
                    <div class="faq-description">
                      <p className="fqa_subjead">Volumizer Hair Additions</p>
                      <h4>Volumizer Hair Additions FAQs</h4>
                      <h5>Your LOCKS UNLOCKED!</h5>
                      <p>
                      Additions We hear your concern at RADIANCE. We understand the queries that need to be answered before you step forward to get your additional hair volumizer. Here are some answers to your questions that might bother you! 
                      </p>

                      <FAQToggle faqs={faqs1} />
                      <p>
                      Every one of us deserves the best for our mane. Experts at RADIANCE offer you a free consultation and answer all your questions along with the best suggestion for your hair. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div class="card">
                  <div class="card-body p-4">
                  <div class="faq-description">
                      <p className="fqa_subjead">Hair Clipping</p>
                      <h4>Hair Clipping FAQs</h4>
                      <FAQToggle faqs={faqs5} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="faq-description">
                      <p className="fqa_subjead">Hair Weaving</p>
                      <h4>Hair Weaving FAQs</h4>
                      <p>There is no external thing to be applied in this method – no glues, no tapes. Your natural hair will be weaved to give the new structure, based on which the elongation will remain. So, this is such a Non-Surgical Hair replacement process, where there is no risk at all. </p>
                      <FAQToggle faqs={faqs4} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default FAQPage;